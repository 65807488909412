import React from 'react'
import { Helmet } from 'react-helmet'
import PageWrapper from '../components/PageWrapper'
import MetaData from '../components/MetaData'
import Hero from '../sections/common/Hero'
import Content from '../sections/about/FeaturesContent'
import { Section, Box, Title, Text, Button } from '../components/Core'
import { Container, Col, Row } from 'react-bootstrap'
import styled from 'styled-components'
import Image from '../assets/image/png/macWebApp.png'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import GetStarted from '../sections/landing1/GetStarted'
import { Link } from 'gatsby'
import setLanguage from '../helpers/languageConfig'
import portfolioHero from '../assets/image/svg/portfolioMgmt.svg'
import { breakpoints } from "../utils";

const ImageShadow = styled(Box)`
  @media (max-width: ${breakpoints.lg}px) {
    margin: 50px 0 20px 0;
  }

  img {
    border-radius: 10px;
    -webkit-filter: drop-shadow(
      ${({ theme }) => `0 52px 42px ${theme.colors.shadow}`}
    );
    filter: drop-shadow(${({ theme }) => `0 52px 42px ${theme.colors.shadow}`});
  }
`

const PortfolioMgmt = () => {
  const { t, ready } = useTranslation(['portfolio'], { useSuspense: false })
  const lang = setLanguage();
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  return ready && (
    <>
      <MetaData page="portfolioManagement" />
      <PageWrapper>
        <Hero
          bg={userDarkMode ? 'black' : 'bg'}
          title={t('title')}
          firstP={t('firstP')}
          img={true}
          imgSrc={portfolioHero}
        >
          {t('subTitle')}
        </Hero>
        <Content
              title={t('neverMiss')}
              text1={t('secondP')}
              bulletTitle={t('riskFree')}
              externalButton={true}
              btnRegular={true}
              extButtonText={t('signup')}
              extButtonUrl={process.env.LS_SIGNUP}
              customBackground={userDarkMode ? 'bgDarkGray' : 'white'}
            />
        <Section bg={userDarkMode ? 'black' : 'bg'}>
          <Container>
            <Row>
              <Col>
                <Title variant="highlight" color={userDarkMode ? '#FAFAFA' : 'black'} >
                  {t('how')}
                </Title>
              </Col>
            </Row>
            <Row>
              <Col lg="5">
                <Text style={{ color: userDarkMode ? 'lightGray' : 'black'}} className="mb-4">
                  {t('connectYour')}
                </Text>
                <Text style={{ color: userDarkMode ? 'lightGray' : 'black'}}>
                  {t('onceConnected')}
                </Text>
                <Button style={{backgroundColor: '#124D95'}} className="mt-5">
                  <Link to={`/${lang}/supported-exchanges/`}>
                    <Text color="#FAFAFA">
                      {t('supportedExchs')}
                    </Text>
                  </Link>
                </Button>
              </Col>
              <Col lg="7">
              <ImageShadow
                data-aos="fade-down"
                data-aos-duration="750"
                data-aos-once="true"
              >
                <img
                  width="100%"
                  height="auto"
                  alt=""
                  className="img-fluid"
                  src={Image}
                />
              </ImageShadow>
              </Col>
            </Row>
          </Container>
        </Section>
        <Content
          button={true}
          btnRegular={true}
          title={t('yourFunds')}
          text1={t('linkedWallets')}
          text2={t('neverHandles')}
          buttonText={t('moreAPIKey')}
          buttonUrl="security"
          customBackground={userDarkMode ? 'bgDarkGray' : 'white'}
        />
        <GetStarted />
      </PageWrapper>
    </>
  )
}
export default PortfolioMgmt
